body {
  background-size: 'cover';
  background-position: "center";
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swagger-download-btn {
  background-color: #61affe;
  border: none;
  color: white;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  margin: 10px 0;
  transition: background-color 0.3s ease;
}

.swagger-download-btn:hover {
  background-color: #49a3e0;
}

.swagger-download-btn:focus {
  outline: none;
}
